<template >
  <div id="StorePredictHistory">
    <div class="blueTitle">历史内容：</div>

    <div v-if="predictHistoryList.length === 0" style="font-size: 1rem">尚无历史资料</div>
    <div class="PredictHistoryTabBlock">
      <img
        class="scrollBtn"
        v-show="hasScroll"
        @click="doScroll(0)"
        style="display: none"
        src="@/assets/shop/ShapeLeft.png"
        alt=""
      />

      <div ref="navScroll" class="PredictHistoryTabs">
        <div
          class="PredictTab"
          v-for="(it, index) in lotteryTypes"
          :key="index"
          :class="chooseTab.type_spell === it.type_spell ? 'PredictTabActive' : ''"
          @click="tabClickHandler(it)"
        >
          {{ it.type }}
        </div>
      </div>

      <img
        class="scrollBtn"
        v-show="hasScroll"
        @click="doScroll(1)"
        style="display: none"
        src="@/assets/shop/ShapeRight.png"
        alt=""
      />
    </div>

    <div class="PredictHistoryCarouselBlock">
      <div class="tabPanel" v-for="(lottery, index) in predictHistoryList" :key="index">
        <div class="tabPanelTitleBlock"> {{ `[ ${lottery.name} ]` }} </div>
        <carousel ref="carouse" :perPage="1" :navigationEnabled="true">
          <slide v-for="(lotteryTab, index2) in lottery.issues" :key="index2 + 10">
            <div class="issueBlock">
              <div
                class="issue"
                v-for="(issue, index3) in lotteryTab"
                :key="index3 + 10000"
                @click="issueClickHandler(lottery.codeName, issue)"
              >
                <span class="issueText">{{ issue }}</span>
                <span> 期预测</span>
              </div>
            </div>
          </slide>

          <template v-slot:navigation v-if="isArrElementEmpty(lottery.issues[1])">
            <div class="leftArrowBtn" @click="navigationClick(0, index)"></div>
            <div class="rightArrowBtn" @click="navigationClick(1, index)"></div>
          </template>
          <template v-slot:navigation v-else> </template>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StorePredictHistory',
  props: {
    shopInfo: {
      type: Object,
    },
  },
  data() {
    return {
      lotteryInfo: new Map(),
      lotteryTypes: [],
      hasScroll: false,
      chooseTab: {
        type: null,
        type_spell: null,
      },
      predictHistoryList: [],
      CONST_PANEL_NUM: 20,
    }
  },
  created() {
    this.initAPI()
    window.addEventListener('resize', this.resizeEvent)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeEvent)
  },
  computed: {},
  mounted() {
    this.callResizeEvent()
  },
  methods: {
    spArr(arr, num) {
      const newArr = []
      for (let i = 0; i < arr.length; ) {
        newArr.push(arr.slice(i, (i += num)))
      }
      return newArr
    },
    async initAPI() {
      this.$store.commit('setLoading', true)
      await this.$store
        .dispatch('shop/getLotteriesAndLotteryTypeWithPredict')
        .then((resArr) => {
          resArr[0].data.forEach((it) => {
            this.lotteryInfo.set(it.lotterycode, it.lotteryname)
          })

          const lotteryTypesApiData = resArr[1]
          this.lotteryTypes = lotteryTypesApiData.data
          if (lotteryTypesApiData.data.length !== 0) {
            this.chooseTab = Object.assign({}, lotteryTypesApiData.data[0])
            this.getPredictHistory(lotteryTypesApiData.data[0].type)
          } else {
            this.$store.commit('setLoading', false)
          }
        })
        .catch(this.$autoCatch)
    },
    resizeEvent() {
      this.hasScroll = this.$refs.navScroll?.scrollWidth > this.$refs.navScroll?.clientWidth
    },
    doScroll(type) {
      if (type === 0) {
        this.$refs.navScroll.scrollLeft -= 200
      } else {
        this.$refs.navScroll.scrollLeft += 200
      }
    },
    callResizeEvent() {
      this.$nextTick(() => {
        this.resizeEvent()
      })
      // 修正預設英文版時firefox 仍不會顯示箭頭問題
      setTimeout(() => {
        this.resizeEvent()
      }, 500)
    },
    tabClickHandler(lotTypeChooseData) {
      this.chooseTab = Object.assign({}, lotTypeChooseData)
      this.getPredictHistory(lotTypeChooseData.type)
    },
    getPredictHistory(type) {
      this.$store.commit('setLoading', true)
      this.$store
        .dispatch('shop/getPredictHistory', { id: this.shopInfo.id, type })
        .then((res) => {
          const typeDatas = res.data[this.chooseTab.type]
          if (typeDatas) {
            // let fakeIssue = new Array(3).fill('20220110').map((it, index) => it + index)
            this.predictHistoryList = Object.keys(typeDatas).map((it, index) => {
              // if (index === 1) {
              //   fakeIssue = new Array(22).fill('20220110').map((it, index) => it + index)
              // }
              return {
                codeName: it,
                name: this.lotteryInfo.get(it),
                issues: this.spArr(typeDatas[it], this.CONST_PANEL_NUM),
                // issues: this.spArr(fakeIssue, this.CONST_PANEL_NUM),
              }
            })
          } else {
            this.$MSG.error('伺服器错误请稍后再试')
          }
        })
        .catch(this.$autoCatch)
        .finally(() => {
          this.$store.commit('setLoading', false)
        })
    },
    navigationClick(type, index) {
      if (type === 0) {
        this.$refs.carouse[index].handleNavigation('backward')
      } else {
        this.$refs.carouse[index].handleNavigation()
      }
    },
    issueClickHandler(code, issue) {
      this.$router.push({
        name: 'PredictHistory',
        query: { id: this.shopInfo.id, code, issue },
      })
    },
    isArrElementEmpty(data) {
      return typeof data !== 'undefined'
    },
  },
}
</script>

<style lang="scss">
.VueCarousel {
  width: 100%;
  .VueCarousel-wrapper {
    min-height: 265.55px;
  }
  .VueCarousel-dot-container {
    margin-top: 0px !important;
    button {
      margin-top: 0px !important;
    }
  }
  .VueCarousel-pagination {
    margin-top: 25px;
    width: 100%;
  }
  .leftArrowBtn {
    position: absolute;
    left: 5%;
    bottom: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7.5px 12px 7.5px 0;
    border-color: transparent #5d5d5d transparent transparent;
    cursor: pointer;
  }
  .rightArrowBtn {
    position: absolute;
    right: 5%;
    bottom: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7.5px 0 7.5px 12px;
    border-color: transparent transparent transparent #5d5d5d;
    cursor: pointer;
  }
}
</style>

<style lang="scss" scoped>
@import '@/views/Member/Store/Store.scss';
#StorePredictHistory {
  .PredictHistoryTabBlock {
    display: flex;
    align-items: center;
    margin-bottom: 17px;
    .scrollBtn {
      cursor: pointer;
    }
    .PredictHistoryTabs {
      display: flex;
      align-items: center;
      overflow-x: auto;
      overflow-y: hidden;
      scroll-behavior: smooth;
      // firefox隱藏卷軸
      scrollbar-width: none;
      // chrome隱藏卷軸
      &::-webkit-scrollbar {
        display: none;
      }

      .PredictTab {
        padding: 10px 60px;
        font-size: 1rem;
        white-space: nowrap;
        cursor: pointer;
      }
      .PredictTabActive {
        color: #47bdc6;
        border-bottom: 4px solid #47bdc6;
      }
    }
  }
  .PredictHistoryCarouselBlock {
    display: flex;
    flex-wrap: wrap;
    .tabPanel {
      width: 48%;
      padding-bottom: 20px;
      border: 1px solid #d7d7d7;
      margin-right: 2%;
      margin-bottom: 17px;
      @media (max-width: 768px) {
        width: 100%;
        margin-right: 0;
      }
      .tabPanelTitleBlock {
        width: 100%;
        height: 40px;
        line-height: 40px;
        background: #47bdc6 0% 0% no-repeat padding-box;
        color: white;
        font-size: 1rem;
      }
      &:nth-child(even) {
        margin-right: 0%;
      }
      .issueBlock {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin-top: 10px;
        .issue {
          cursor: pointer;
          padding: 5px;
          width: 50%;
          .issueText {
            color: #3884eb;
          }
        }
      }
    }
  }
}
</style>
